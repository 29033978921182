import service from "@/api/service";
import { GetResponse } from "@/api/recruit-shop/response";
import { SearchRequest } from "@/api/recruit-shop/request";
import { SearchResponse } from "@/api/recruit-shop/response";
import {RecruitRequest} from "@/api/recruit-shop/request";
import {RecruitResponse} from "@/api/recruit-shop/response";
import {RegisterRequest} from "@/api/recruit-shop/request";
import {RegisterResponse} from "@/api/recruit-shop/response";

/**
 * 求人管理店舗一覧・検索APIをコールします。
 *
 * @param searchRequest 求人管理店舗検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-recruit-shop", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 求人管理店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-recruit-shop");
  return response.data as GetResponse;
}

/**
 * 求人情報取得をコールします。
 *
 * @param recruitRequest 求人情報登録更新のリクエストボディ
 */
 export async function recruit(recruitRequest: RecruitRequest) {
     const response = await service.post("/get-shop-recruit", recruitRequest);
     return response.data as RecruitResponse;
 }

/**
 * 求人情報取得登録更新APIをコールします。
 *
 * @param registerRequest
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
    const response = await service.post("/regist-recruit", registerRequest);
    return response.data as RegisterResponse;
}